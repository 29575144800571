import { Pagination, styled, Dialog, Typography, Box, Tab, Tabs, Grid, useTheme } from '@mui/material';
import { PoolType } from 'helpers';
import { useEffect, useState } from 'react';
import { getStakingApi } from 'swagger';
import { PopupPool } from '../popup-pool';
import { PopupStandardPool } from '../popup-standard-pool';
import { TableList } from './table';
import { TableListMobile } from './table-mobile';
import { isFinished } from 'utils';
import { PopupPrivatePool } from '../popup-private-pool';
import { useSelector } from 'react-redux';

import './style.scss';
import SwipeableViews from 'react-swipeable-views';

const CustomDialog = styled(Dialog)`
  .MuiPaper-root {
    background-color: transparent;
    width: 100%;
    @media (max-width: 500px) {
      margin: 0px;
    }
  }
`;

const CustomPagination = styled(Pagination)`
  .MuiPaginationItem-root {
    background: #2b2b31;
    border: 1px solid #45454f !important;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
  }
  .MuiPaginationItem-ellipsis {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Mui-selected {
    background: #2b2b31 !important;
    border: 1px solid #eece7c !important;
    border-radius: 4px !important;
    color: #eece7c !important;
  }
`;

const StyledTabs = styled(Tabs)(() => ({
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '.MuiTabs-indicator': {
        display: 'none',
    },
}));

const StyledTab = styled(Tab)(() => ({
    color: '#E2E4E9',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: '8px',
    fontSize: '16px',
    border: '1px #383A3E solid',
    borderRadius: '28px',
    position: 'relative',
    background: 'transparent',
    marginBottom: '6px',
    textTransform: 'none',
    '&.Mui-selected': {
        color: '#EECE7C',
        border: '1px solid #EECE7C',
        '&:before': {
            content: '" "',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: -1,
            borderRadius: 'inherit',
        },
    },
}));


const TabPanel = (props: {
    children?: React.ReactNode;
    index: number;
    value: number;
    [key: string]: any;
}) => {
    const { index, value, children, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ py: 4 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

function a11yProps(index: number) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export const ClosePoolsTable: React.FC<{}> = () => {
    useSelector<any, any>(
        ({ profile }: any) => profile
    );
    // const privateSummerSaleInfo: any = isLoggedIn ? privateSummerSale[96] : {}

    const [chosenIndex, setChosenIndex] = useState(0);
    const [isOpenPopupFlexible, setIsOpenPopupFlexible] = useState(false);
    const [isOpenPopupStandard, setIsOpenPopupStandard] = useState(false);
    const [isOpenPopupPrivate, setIsOpenPopupPrivate] = useState(false);
    const [value, setValue] = useState(0);
    const handleChange = (event: any, newValue: number) => {
      setValue(newValue);
    };
    const handleChangeIndex = (index: number) => {
        setValue(index);
    };
    const [poolsList, setPoolsList] = useState<PoolType[]>([]);
    const [tradionalPoolList, setTradionalPoolList] =  useState<PoolType[]>([]);
    const [standardPoolList, setStandardPoolList] =  useState<PoolType[]>([]);
    const [mediumPoolList, setMediumPoolList] =  useState<PoolType[]>([]);
    const theme = useTheme();
    const loadData = async () => {
        const { data }: { data: PoolType[] } = await getStakingApi()
            .apiStakingPoolsGet()
            .then((res) => res.json())

        const closedPools = data.filter((pool) => isFinished(pool.close_at)).sort((a, b) => b.close_at.localeCompare(a.close_at));
        const tradionalPools = closedPools.filter((pool) => pool.pool_name.includes("Traditional"));
        const standardPools = closedPools.filter((pool) => pool.pool_name.includes("Standard"));
        const mediumPools = closedPools.filter((pool) => pool.pool_name.includes("Medium"));

        console.log(mediumPools);

        setPoolsList([
            ...closedPools,
        ]);

        setTradionalPoolList([
            ...tradionalPools,
        ]);

        setStandardPoolList([
            ...standardPools,
        ]);

        setMediumPoolList([
            ...mediumPools,
        ]);
    }

    useEffect(() => {
        loadData()
    }, []);

    return (
        <div className="w-full">
            <StyledTabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="scrollable"
                scrollButtons="auto"
            >
                <StyledTab label="All pool" {...a11yProps(0)} />
                <StyledTab label="Traditional" {...a11yProps(1)} />
                <StyledTab label="Standard" {...a11yProps(2)} />
                <StyledTab label="Medium Term" {...a11yProps(3)} />
            </StyledTabs>
            <SwipeableViews
                slideStyle={{ overflow: 'visible' }}
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <Grid container className="w-full flex flex-col gap-4">
                        <div className="flex flex-col gap-8 items-center">
                            <div
                                className="rounded-2xl overflow-hidden w-full"
                                style={{ border: '1px solid #45454f' }}
                            >
                                <div className="md:block hidden">
                                    <TableList
                                        poolsList={poolsList}
                                        setChosenIndex={(index: number) => setChosenIndex(index)}
                                        setIsOpenPopupFlexible={(open: boolean) =>
                                            setIsOpenPopupFlexible(open)
                                        }
                                        setIsOpenPopupStandard={(open: boolean) =>
                                            setIsOpenPopupStandard(open)
                                        }
                                        setIsOpenPopupPrivate={setIsOpenPopupPrivate}
                                        isHasFooter={true}
                                    />
                                </div>
                                <div className="block md:hidden">
                                    <TableListMobile
                                        poolsList={poolsList}
                                        setChosenIndex={(index: number) => setChosenIndex(index)}
                                        setIsOpenPopupFlexible={(open: boolean) =>
                                            setIsOpenPopupFlexible(open)
                                        }
                                        setIsOpenPopupStandard={(open: boolean) =>
                                            setIsOpenPopupStandard(open)
                                        }
                                        setIsOpenPopupPrivate={setIsOpenPopupPrivate}
                                        isHasFooter={true}
                                    />
                                </div>
                            </div>
                            <CustomPagination count={1} />
                            {isOpenPopupFlexible && (
                                <CustomDialog maxWidth="md" open={isOpenPopupFlexible}>
                                    <PopupPool
                                        handleClose={() => setIsOpenPopupFlexible(false)}
                                        poolData={poolsList[chosenIndex]}
                                    />
                                </CustomDialog>
                            )}
                            {isOpenPopupStandard && (
                                <CustomDialog maxWidth="md" open={isOpenPopupStandard}>
                                    <PopupStandardPool
                                        handleClose={() => setIsOpenPopupStandard(false)}
                                        poolData={poolsList[chosenIndex]}
                                    />
                                </CustomDialog>
                            )}
                            {isOpenPopupPrivate && (
                                <CustomDialog maxWidth="md" open={isOpenPopupPrivate}>
                                    <PopupPrivatePool
                                        handleClose={() => setIsOpenPopupPrivate(false)}
                                        poolData={poolsList[chosenIndex]}
                                    />
                                </CustomDialog>
                            )}
                        </div>
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <Grid container className="w-full flex flex-col gap-4">
                        <div className="flex flex-col gap-8 items-center">
                            <div
                                className="rounded-2xl overflow-hidden w-full"
                                style={{ border: '1px solid #45454f' }}
                            >
                                <div className="md:block hidden">
                                    <TableList
                                        poolsList={tradionalPoolList}
                                        setChosenIndex={(index: number) => setChosenIndex(index)}
                                        setIsOpenPopupFlexible={(open: boolean) =>
                                            setIsOpenPopupFlexible(open)
                                        }
                                        setIsOpenPopupStandard={(open: boolean) =>
                                            setIsOpenPopupStandard(open)
                                        }
                                        setIsOpenPopupPrivate={setIsOpenPopupPrivate}
                                        isHasFooter={true}
                                    />
                                </div>
                                <div className="block md:hidden">
                                    <TableListMobile
                                        poolsList={tradionalPoolList}
                                        setChosenIndex={(index: number) => setChosenIndex(index)}
                                        setIsOpenPopupFlexible={(open: boolean) =>
                                            setIsOpenPopupFlexible(open)
                                        }
                                        setIsOpenPopupStandard={(open: boolean) =>
                                            setIsOpenPopupStandard(open)
                                        }
                                        setIsOpenPopupPrivate={setIsOpenPopupPrivate}
                                        isHasFooter={true}
                                    />
                                </div>
                            </div>
                            <CustomPagination count={1} />
                            {isOpenPopupFlexible && (
                                <CustomDialog maxWidth="md" open={isOpenPopupFlexible}>
                                    <PopupPool
                                        handleClose={() => setIsOpenPopupFlexible(false)}
                                        poolData={tradionalPoolList[chosenIndex]}
                                    />
                                </CustomDialog>
                            )}
                            {isOpenPopupStandard && (
                                <CustomDialog maxWidth="md" open={isOpenPopupStandard}>
                                    <PopupStandardPool
                                        handleClose={() => setIsOpenPopupStandard(false)}
                                        poolData={tradionalPoolList[chosenIndex]}
                                    />
                                </CustomDialog>
                            )}
                            {isOpenPopupPrivate && (
                                <CustomDialog maxWidth="md" open={isOpenPopupPrivate}>
                                    <PopupPrivatePool
                                        handleClose={() => setIsOpenPopupPrivate(false)}
                                        poolData={tradionalPoolList[chosenIndex]}
                                    />
                                </CustomDialog>
                            )}
                        </div>
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                    <Grid container className="w-full flex flex-col gap-4">
                        <div className="flex flex-col gap-8 items-center">
                            <div
                                className="rounded-2xl overflow-hidden w-full"
                                style={{ border: '1px solid #45454f' }}
                            >
                                <div className="md:block hidden">
                                    <TableList
                                        poolsList={standardPoolList}
                                        setChosenIndex={(index: number) => setChosenIndex(index)}
                                        setIsOpenPopupFlexible={(open: boolean) =>
                                            setIsOpenPopupFlexible(open)
                                        }
                                        setIsOpenPopupStandard={(open: boolean) =>
                                            setIsOpenPopupStandard(open)
                                        }
                                        setIsOpenPopupPrivate={setIsOpenPopupPrivate}
                                        isHasFooter={true}
                                    />
                                </div>
                                <div className="block md:hidden">
                                    <TableListMobile
                                        poolsList={standardPoolList}
                                        setChosenIndex={(index: number) => setChosenIndex(index)}
                                        setIsOpenPopupFlexible={(open: boolean) =>
                                            setIsOpenPopupFlexible(open)
                                        }
                                        setIsOpenPopupStandard={(open: boolean) =>
                                            setIsOpenPopupStandard(open)
                                        }
                                        setIsOpenPopupPrivate={setIsOpenPopupPrivate}
                                        isHasFooter={true}
                                    />
                                </div>
                            </div>
                            <CustomPagination count={1} />
                            {isOpenPopupFlexible && (
                                <CustomDialog maxWidth="md" open={isOpenPopupFlexible}>
                                    <PopupPool
                                        handleClose={() => setIsOpenPopupFlexible(false)}
                                        poolData={standardPoolList[chosenIndex]}
                                    />
                                </CustomDialog>
                            )}
                            {isOpenPopupStandard && (
                                <CustomDialog maxWidth="md" open={isOpenPopupStandard}>
                                    <PopupStandardPool
                                        handleClose={() => setIsOpenPopupStandard(false)}
                                        poolData={standardPoolList[chosenIndex]}
                                    />
                                </CustomDialog>
                            )}
                            {isOpenPopupPrivate && (
                                <CustomDialog maxWidth="md" open={isOpenPopupPrivate}>
                                    <PopupPrivatePool
                                        handleClose={() => setIsOpenPopupPrivate(false)}
                                        poolData={standardPoolList[chosenIndex]}
                                    />
                                </CustomDialog>
                            )}
                        </div>
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={3} dir={theme.direction}>
                    <Grid container className="w-full flex flex-col gap-4">
                        <div className="flex flex-col gap-8 items-center">
                            <div
                                className="rounded-2xl overflow-hidden w-full"
                                style={{ border: '1px solid #45454f' }}
                            >
                                <div className="md:block hidden">
                                    <TableList
                                        poolsList={mediumPoolList}
                                        setChosenIndex={(index: number) => setChosenIndex(index)}
                                        setIsOpenPopupFlexible={(open: boolean) =>
                                            setIsOpenPopupFlexible(open)
                                        }
                                        setIsOpenPopupStandard={(open: boolean) =>
                                            setIsOpenPopupStandard(open)
                                        }
                                        setIsOpenPopupPrivate={setIsOpenPopupPrivate}
                                        isHasFooter={true}
                                    />
                                </div>
                                <div className="block md:hidden">
                                    <TableListMobile
                                        poolsList={mediumPoolList}
                                        setChosenIndex={(index: number) => setChosenIndex(index)}
                                        setIsOpenPopupFlexible={(open: boolean) =>
                                            setIsOpenPopupFlexible(open)
                                        }
                                        setIsOpenPopupStandard={(open: boolean) =>
                                            setIsOpenPopupStandard(open)
                                        }
                                        setIsOpenPopupPrivate={setIsOpenPopupPrivate}
                                        isHasFooter={true}
                                    />
                                </div>
                            </div>
                            <CustomPagination count={1} />
                            {isOpenPopupFlexible && (
                                <CustomDialog maxWidth="md" open={isOpenPopupFlexible}>
                                    <PopupPool
                                        handleClose={() => setIsOpenPopupFlexible(false)}
                                        poolData={mediumPoolList[chosenIndex]}
                                    />
                                </CustomDialog>
                            )}
                            {isOpenPopupStandard && (
                                <CustomDialog maxWidth="md" open={isOpenPopupStandard}>
                                    <PopupStandardPool
                                        handleClose={() => setIsOpenPopupStandard(false)}
                                        poolData={mediumPoolList[chosenIndex]}
                                    />
                                </CustomDialog>
                            )}
                            {isOpenPopupPrivate && (
                                <CustomDialog maxWidth="md" open={isOpenPopupPrivate}>
                                    <PopupPrivatePool
                                        handleClose={() => setIsOpenPopupPrivate(false)}
                                        poolData={mediumPoolList[chosenIndex]}
                                    />
                                </CustomDialog>
                            )}
                        </div>
                    </Grid>
                </TabPanel>
            </SwipeableViews>
        </div>
    );
};
