import { styled, Dialog } from '@mui/material';
import { EPoolType, PoolType } from 'helpers';
import { useEffect, useState } from 'react';
import { getStakingApi } from 'swagger';
import { PopupPool } from '../popup-pool';
import { PopupStandardPool } from '../popup-standard-pool';
import { TableList } from './table';
import { TableListMobile } from './table-mobile';
import { isFinished, isStarted } from 'utils';
import { PopupPrivatePool } from '../popup-private-pool';
import { useSelector } from 'react-redux';

import './style.scss';
import BigNumber from 'bignumber.js';
import { getDecimals, ferrumPoolTotalStaked, standardPoolTotalStaked } from 'contracts/helpers';

const CustomDialog = styled(Dialog)`
  .MuiPaper-root {
    background-color: transparent;
    width: 100%;
    @media (max-width: 500px) {
      margin: 0px;
    }
  }
`;


export const OpenPoolsTable: React.FC<{}> = () => {
  const { isLoggedIn, address: walletAddress } = useSelector<any, any>(
    ({ profile }: any) => profile,
  );

  const [chosenIndex, setChosenIndex] = useState(0);
  const [isOpenPopupFlexible, setIsOpenPopupFlexible] = useState(false);
  const [isOpenPopupStandard, setIsOpenPopupStandard] = useState(false);
  const [isOpenPopupPrivate, setIsOpenPopupPrivate] = useState(false);

  const [poolsList, setPoolsList] = useState<PoolType[]>([]);

  const getStakedAmount = async (poolData: PoolType) => {
    try {
      const decimal = await getDecimals(poolData?.token_address);
      let res;
      if (poolData?.type === EPoolType.Ferrum) {
        res = await ferrumPoolTotalStaked(poolData?.contract_address);
      } else {
        res = await standardPoolTotalStaked(poolData?.contract_address);
      }
      const stakedAmount = new BigNumber(res).div(10 ** decimal).toNumber();
      return stakedAmount;
    } catch (e) {
      console.error('getStakedAmount', e);
    }
  }

  const loadData = async () => {
    const { data }: { data: PoolType[] } = await getStakingApi()
      .apiStakingPoolsGet()
      .then((res) => res.json())

    const openPools = data
      .filter(
        (pool) => isStarted(pool.start_at) && !isFinished(pool.close_at),
      )
      .sort((a, b) => a.start_at > b.start_at ? -1 : 1);

    for (const openPool of openPools) {
      openPool.stakedAmount = await getStakedAmount(openPool)
    }

    setPoolsList([
      ...openPools
    ]);
  }

  useEffect(() => {
    loadData()
  }, []);

  return (
    <div className="flex flex-col gap-8 items-center">
      <div
        className="rounded-2xl overflow-hidden w-full"
        style={{ border: '1px solid #45454f' }}
      >
        <div className="md:block hidden">
          <TableList
            poolsList={poolsList}
            setChosenIndex={(index: number) => setChosenIndex(index)}
            setIsOpenPopupFlexible={(open: boolean) =>
              setIsOpenPopupFlexible(open)
            }
            setIsOpenPopupStandard={(open: boolean) =>
              setIsOpenPopupStandard(open)
            }
            setIsOpenPopupPrivate={setIsOpenPopupPrivate}
            isHasFooter={false}
          />
        </div>
        <div className="block md:hidden">
          <TableListMobile
            poolsList={poolsList}
            setChosenIndex={(index: number) => setChosenIndex(index)}
            setIsOpenPopupFlexible={(open: boolean) =>
              setIsOpenPopupFlexible(open)
            }
            setIsOpenPopupStandard={(open: boolean) =>
              setIsOpenPopupStandard(open)
            }
            setIsOpenPopupPrivate={setIsOpenPopupPrivate}
            isHasFooter={false}
          />
        </div>
      </div>
      {/* <CustomPagination count={1} /> */}
      {isOpenPopupFlexible && (
        <CustomDialog maxWidth="md" open={isOpenPopupFlexible}>
          <PopupPool
            handleClose={() => setIsOpenPopupFlexible(false)}
            poolData={poolsList[chosenIndex]}
          />
        </CustomDialog>
      )}
      {isOpenPopupStandard && (
        <CustomDialog maxWidth="md" open={isOpenPopupStandard}>
          <PopupStandardPool
            handleClose={() => setIsOpenPopupStandard(false)}
            poolData={poolsList[chosenIndex]}
          />
        </CustomDialog>
      )}
      {isOpenPopupPrivate && (
        <CustomDialog maxWidth="md" open={isOpenPopupPrivate}>
          <PopupPrivatePool
            handleClose={() => setIsOpenPopupPrivate(false)}
            poolData={poolsList[chosenIndex]}
          />
        </CustomDialog>
      )}
    </div>
  );
};
