// @ts-nocheck
import { getApiService } from "../helpers";

/*
    Launchpad backend API generated
    The Launchpad API description
    version: 2.0.0

*/

export class Configuration {
	basePath? = '';
	fetchMethod = fetch;
	headers?: any = {};
    getHeaders: any = () => { return {} };
    responseHandler: any = null;
    errorHandler: any = null;

	constructor(config: Configuration | any) {
	    if (config) {
	        if (config.basePath) {
                this.basePath = config.basePath;
            }
            if (config.fetchMethod) {
                this.fetchMethod = config.fetchMethod;
            }
            if (config.headers) {
                this.headers = config.headers;
            }
            if (config.getHeaders) {
                this.getHeaders = config.getHeaders;
            }
            if (config.responseHandler) {
                this.responseHandler = config.responseHandler;
            }
            if (config.errorHandler) {
                this.errorHandler = config.errorHandler;
            }
	    }
	}
}

const setAdditionalParams = (params = [], additionalParams = {}) => {
    Object.keys(additionalParams).forEach(key => {
        if(additionalParams[key]) {
            params.append(key, additionalParams[key]);
        }
    });
};

export class Web3LoginDTO {
	addr?: string;
	nonce?: string;
	signature?: string;

	constructor(obj: Web3LoginDTO) {
        this.addr = obj.addr;
        this.nonce = obj.nonce;
        this.signature = obj.signature;
	}
}

export class AdminLogInDto {
	username?: string;
	password?: string;

	constructor(obj: AdminLogInDto) {
        this.username = obj.username;
        this.password = obj.password;
	}
}

export class CampaignDtoSection1 {
	campaignType?: string;
	name?: string;
	registrationTime?: string;
	startTime?: string;
	endTime?: string;
	totalRaise?: number;
	tokenPrice?: number;
	distributedNetwork?: string;
	distributedInfo?: string;
	currencies?: string[];
	avatar?: string;
	cover?: string;
	video_cover?: string;
	displayClaimingPageUrl?: any;
	claimingPageUrl?: string;
	safeguardEnable?: any;
	safeguardType?: number;
	safeguardTokenAddress?: string;
	safeguardFinanceWallet?: string;
	safeguardUrl?: string;
	poolType?: string;
	promotion?: string;
	extraInformationImageUrl?: string;
	method?: string;
	vesting_enable?: any;
	vesting?: string;
	vesting_contract_address?: string;
	treasure_contract_address?: string;
	nft_type?: string;
	refundsEnable?: any;
	refund_end_time?: string;
	refund_start_time?: string;
	on_banner?: any;
	setting?: any;
	popup_enable?: any;
	popup_url?: string;
	popup_img?: string;

	constructor(obj: CampaignDtoSection1) {
        this.campaignType = obj.campaignType;
        this.name = obj.name;
        this.registrationTime = obj.registrationTime;
        this.startTime = obj.startTime;
        this.endTime = obj.endTime;
        this.totalRaise = obj.totalRaise;
        this.tokenPrice = obj.tokenPrice;
        this.distributedNetwork = obj.distributedNetwork;
        this.distributedInfo = obj.distributedInfo;
        this.currencies = obj.currencies;
        this.avatar = obj.avatar;
        this.cover = obj.cover;
        this.video_cover = obj.video_cover;
        this.displayClaimingPageUrl = obj.displayClaimingPageUrl;
        this.claimingPageUrl = obj.claimingPageUrl;
        this.safeguardEnable = obj.safeguardEnable;
        this.safeguardType = obj.safeguardType;
        this.safeguardTokenAddress = obj.safeguardTokenAddress;
        this.safeguardFinanceWallet = obj.safeguardFinanceWallet;
        this.safeguardUrl = obj.safeguardUrl;
        this.poolType = obj.poolType;
        this.promotion = obj.promotion;
        this.extraInformationImageUrl = obj.extraInformationImageUrl;
        this.method = obj.method;
        this.vesting_enable = obj.vesting_enable;
        this.vesting = obj.vesting;
        this.vesting_contract_address = obj.vesting_contract_address;
        this.treasure_contract_address = obj.treasure_contract_address;
        this.nft_type = obj.nft_type;
        this.refundsEnable = obj.refundsEnable;
        this.refund_end_time = obj.refund_end_time;
        this.refund_start_time = obj.refund_start_time;
        this.on_banner = obj.on_banner;
        this.setting = obj.setting;
        this.popup_enable = obj.popup_enable;
        this.popup_url = obj.popup_url;
        this.popup_img = obj.popup_img;
	}
}

export class CampaignDtoSection2 {
	tokenName?: string;
	initialCirculatingSupply?: string;
	totalSupply?: string;
	tokenListingDate?: string;
	initialMarketCap?: string;
	vestingDetails?: string;
	shortDescription?: string;
	description?: any;
	baseAllocation?: string;

	constructor(obj: CampaignDtoSection2) {
        this.tokenName = obj.tokenName;
        this.initialCirculatingSupply = obj.initialCirculatingSupply;
        this.totalSupply = obj.totalSupply;
        this.tokenListingDate = obj.tokenListingDate;
        this.initialMarketCap = obj.initialMarketCap;
        this.vestingDetails = obj.vestingDetails;
        this.shortDescription = obj.shortDescription;
        this.description = obj.description;
        this.baseAllocation = obj.baseAllocation;
	}
}

export class Rounds {
	type?: string;
	startTime?: string;
	endTime?: string;
	vip_access_only?: any;

	constructor(obj: Rounds) {
        this.type = obj.type;
        this.startTime = obj.startTime;
        this.endTime = obj.endTime;
        this.vip_access_only = obj.vip_access_only;
	}
}

export class CampaignDtoSection3 {
	rounds?: Rounds[];
	base_allocation?: string;
	fcfs_limit_amount?: number;

	constructor(obj: CampaignDtoSection3) {
        this.rounds = obj.rounds;
        this.base_allocation = obj.base_allocation;
        this.fcfs_limit_amount = obj.fcfs_limit_amount;
	}
}

export class UpdateCampaignDto {
	section1?: CampaignDtoSection1;
	section2?: CampaignDtoSection2;
	section3?: CampaignDtoSection3;

	constructor(obj: UpdateCampaignDto) {
        this.section1 = obj.section1;
        this.section2 = obj.section2;
        this.section3 = obj.section3;
	}
}

export class UpdateCampaignStatusDto {
	status?: number;

	constructor(obj: UpdateCampaignStatusDto) {
        this.status = obj.status;
	}
}

export class UserCampaignRegistration {
	buyingWallet?: string;
	receivingWallet?: string;
	email?: string;
	telegram?: string;

	constructor(obj: UserCampaignRegistration) {
        this.buyingWallet = obj.buyingWallet;
        this.receivingWallet = obj.receivingWallet;
        this.email = obj.email;
        this.telegram = obj.telegram;
	}
}

export class CreateOrderDto {
	buyerAddress?: string;
	itemId?: string;
	amount?: number;
	name?: string;
	currency?: string;

	constructor(obj: CreateOrderDto) {
        this.buyerAddress = obj.buyerAddress;
        this.itemId = obj.itemId;
        this.amount = obj.amount;
        this.name = obj.name;
        this.currency = obj.currency;
	}
}

export class UpdateWhiteListRecordDto {
	user_id?: string;
	user_email?: string;
	user_wallet_addr?: any;
	vip_level_name?: string;
	receivingWallet?: string;
	allocate?: number;

	constructor(obj: UpdateWhiteListRecordDto) {
        this.user_id = obj.user_id;
        this.user_email = obj.user_email;
        this.user_wallet_addr = obj.user_wallet_addr;
        this.vip_level_name = obj.vip_level_name;
        this.receivingWallet = obj.receivingWallet;
        this.allocate = obj.allocate;
	}
}

export class CreateUploadLinkDto {
	fileName?: string;
	type?: string;

	constructor(obj: CreateUploadLinkDto) {
        this.fileName = obj.fileName;
        this.type = obj.type;
	}
}

export class RpcConfigurationDto {
	chainName?: string;
	rpc?: string;
	explorer?: string;
	chainId?: string;
	symbol?: string;
	decimal?: number;
	content?: string;
	isTestnet?: any;
	enable?: any;

	constructor(obj: RpcConfigurationDto) {
        this.chainName = obj.chainName;
        this.rpc = obj.rpc;
        this.explorer = obj.explorer;
        this.chainId = obj.chainId;
        this.symbol = obj.symbol;
        this.decimal = obj.decimal;
        this.content = obj.content;
        this.isTestnet = obj.isTestnet;
        this.enable = obj.enable;
	}
}

export class WalletAddr {
	ethereum?: string;
	bnb_chain?: string;
	polygon?: string;
	solana?: string;
	optimism?: string;
	arbitrum?: string;
	sui?: string;

	constructor(obj: WalletAddr) {
        this.ethereum = obj.ethereum;
        this.bnb_chain = obj.bnb_chain;
        this.polygon = obj.polygon;
        this.solana = obj.solana;
        this.optimism = obj.optimism;
        this.arbitrum = obj.arbitrum;
        this.sui = obj.sui;
	}
}

export class UpdateUserProfileDto {
	wallet_addr?: WalletAddr;
	email?: string;
	telegram?: string;

	constructor(obj: UpdateUserProfileDto) {
        this.wallet_addr = obj.wallet_addr;
        this.email = obj.email;
        this.telegram = obj.telegram;
	}
}

export class UpdateOderDto {
	paymentSuccess?: any;
	transactionHash?: string;

	constructor(obj: UpdateOderDto) {
        this.paymentSuccess = obj.paymentSuccess;
        this.transactionHash = obj.transactionHash;
	}
}

export class UpdateOderStatusDto {
	status?: number;

	constructor(obj: UpdateOderStatusDto) {
        this.status = obj.status;
	}
}

export class StakeUnStakeDto {
	amount?: number;
	tx_hash?: string;

	constructor(obj: StakeUnStakeDto) {
        this.amount = obj.amount;
        this.tx_hash = obj.tx_hash;
	}
}

export class CreateUpdateStakingPoolDto {
	id?: string;
	pool_name?: string;
	type?: number;
	start_at?: string;
	close_at?: string;
	est_apr?: any;
	staking_cap?: number;
	network?: string;
	token_name?: string;
	token_address?: string;
	status?: number;
	contract_address?: string;
	pool_index?: number;
	details_url?: string;
	counting_enable?: any;

	constructor(obj: CreateUpdateStakingPoolDto) {
        this.id = obj.id;
        this.pool_name = obj.pool_name;
        this.type = obj.type;
        this.start_at = obj.start_at;
        this.close_at = obj.close_at;
        this.est_apr = obj.est_apr;
        this.staking_cap = obj.staking_cap;
        this.network = obj.network;
        this.token_name = obj.token_name;
        this.token_address = obj.token_address;
        this.status = obj.status;
        this.contract_address = obj.contract_address;
        this.pool_index = obj.pool_index;
        this.details_url = obj.details_url;
        this.counting_enable = obj.counting_enable;
	}
}

export class RefundClaimRequestDto {
	claimed_amount?: number;
	amount?: number;
	total_amount?: number;
	locked_amount?: number;
	isRefund?: any;

	constructor(obj: RefundClaimRequestDto) {
        this.claimed_amount = obj.claimed_amount;
        this.amount = obj.amount;
        this.total_amount = obj.total_amount;
        this.locked_amount = obj.locked_amount;
        this.isRefund = obj.isRefund;
	}
}

export class ConfirmRefundClaimRequestDto {
	transactionHash?: string;

	constructor(obj: ConfirmRefundClaimRequestDto) {
        this.transactionHash = obj.transactionHash;
	}
}

export class SaleItemDTO {
	payment_token_id?: string;
	price?: number;

	constructor(obj: SaleItemDTO) {
        this.payment_token_id = obj.payment_token_id;
        this.price = obj.price;
	}
}

export class CreateUpdateItemsDTO {
	sale_items?: SaleItemDTO[];
	name?: string;
	image?: string;
	amount?: number;
	price?: number;
	discount?: number;
	order_no?: number;

	constructor(obj: CreateUpdateItemsDTO) {
        this.sale_items = obj.sale_items;
        this.name = obj.name;
        this.image = obj.image;
        this.amount = obj.amount;
        this.price = obj.price;
        this.discount = obj.discount;
        this.order_no = obj.order_no;
	}
}

export class SaleItemResponse {
	payment_token_id?: string;
	payment_token_display?: string;
	price?: number;

	constructor(obj: SaleItemResponse) {
        this.payment_token_id = obj.payment_token_id;
        this.payment_token_display = obj.payment_token_display;
        this.price = obj.price;
	}
}

export class StatusItemResponse {
	id?: string;
	sale_items?: SaleItemResponse[];
	name?: string;
	image?: string;
	amount?: number;
	amount_left?: number;
	price?: number;
	discount?: number;
	order_no?: number;

	constructor(obj: StatusItemResponse) {
        this.id = obj.id;
        this.sale_items = obj.sale_items;
        this.name = obj.name;
        this.image = obj.image;
        this.amount = obj.amount;
        this.amount_left = obj.amount_left;
        this.price = obj.price;
        this.discount = obj.discount;
        this.order_no = obj.order_no;
	}
}

export class CreateUpdateAirdropDto {
	event_name?: string;
	reward?: string;
	start_at?: string;
	close_at?: string;
	publish?: any;
	event_url?: string;
	winner_list?: string;
	cover_url?: string;
	order_no?: number;
	network?: string;

	constructor(obj: CreateUpdateAirdropDto) {
        this.event_name = obj.event_name;
        this.reward = obj.reward;
        this.start_at = obj.start_at;
        this.close_at = obj.close_at;
        this.publish = obj.publish;
        this.event_url = obj.event_url;
        this.winner_list = obj.winner_list;
        this.cover_url = obj.cover_url;
        this.order_no = obj.order_no;
        this.network = obj.network;
	}
}

export class VestingContractResponse {
	id?: string;
	name?: string;
	type?: number;
	address?: string;
	tokenAddress?: string;
	tokenSymbol?: string;
	options?: any;

	constructor(obj: VestingContractResponse) {
        this.id = obj.id;
        this.name = obj.name;
        this.type = obj.type;
        this.address = obj.address;
        this.tokenAddress = obj.tokenAddress;
        this.tokenSymbol = obj.tokenSymbol;
        this.options = obj.options;
	}
}

export class CreateUpdateVestingContractDto {
	name?: string;
	type?: number;
	address?: string;
	tokenAddress?: string;
	tokenSymbol?: string;
	options?: any;
	setting?: any;

	constructor(obj: CreateUpdateVestingContractDto) {
        this.name = obj.name;
        this.type = obj.type;
        this.address = obj.address;
        this.tokenAddress = obj.tokenAddress;
        this.tokenSymbol = obj.tokenSymbol;
        this.options = obj.options;
        this.setting = obj.setting;
	}
}

export class CreateVestingUserDto {
	address?: string;
	value?: number;
	vestingContractId?: string;

	constructor(obj: CreateVestingUserDto) {
        this.address = obj.address;
        this.value = obj.value;
        this.vestingContractId = obj.vestingContractId;
	}
}

export class VestingUserResponse {
	id?: string;
	address?: string;
	value?: number;

	constructor(obj: VestingUserResponse) {
        this.id = obj.id;
        this.address = obj.address;
        this.value = obj.value;
	}
}

export class MethodOptions {
	headers?: any = {};
	returnResponse?: boolean = false;

	constructor(options: MethodOptions) {
		if (options.headers) {
			this.headers = options.headers;
		}
		if (options.returnResponse) {
			this.returnResponse = options.returnResponse;
		}
	}
}

export class ApiAuthAdminNonceAddrGetArgs {
	addr?: string;

	constructor(args: ApiAuthAdminNonceAddrGetArgs) {
        this.addr = args.addr;
	}
}

export class ApiAuthUserNonceAddrGetArgs {
	addr?: string;

	constructor(args: ApiAuthUserNonceAddrGetArgs) {
        this.addr = args.addr;
	}
}

export class ApiCampaignGetArgs {
	limit?: number;
	page?: number;
	keyword?: string;
	status?: string[];
	types?: string[];
	includeRemainQuota?: any;
	includePurchasedAmount?: any;
	on_banner?: any;
	includePrivateCampaign?: any;
	orderBy?: string;

	constructor(args: ApiCampaignGetArgs) {
        this.limit = args.limit;
        this.page = args.page;
        this.keyword = args.keyword;
        this.status = args.status;
        this.types = args.types;
        this.includeRemainQuota = args.includeRemainQuota;
        this.includePurchasedAmount = args.includePurchasedAmount;
        this.on_banner = args.on_banner;
        this.includePrivateCampaign = args.includePrivateCampaign;
        this.orderBy = args.orderBy;
	}
}

export class ApiCampaignIdPutArgs {
	id?: string;

	constructor(args: ApiCampaignIdPutArgs) {
        this.id = args.id;
	}
}

export class ApiCampaignIdGetArgs {
	id?: string;
	section?: number;

	constructor(args: ApiCampaignIdGetArgs) {
        this.id = args.id;
        this.section = args.section;
	}
}

export class ApiCampaignIdDeleteArgs {
	id?: string;

	constructor(args: ApiCampaignIdDeleteArgs) {
        this.id = args.id;
	}
}

export class ApiCampaignIdStatusPutArgs {
	id?: string;

	constructor(args: ApiCampaignIdStatusPutArgs) {
        this.id = args.id;
	}
}

export class ApiCampaignIdToRawIdGetArgs {
	id?: string;

	constructor(args: ApiCampaignIdToRawIdGetArgs) {
        this.id = args.id;
	}
}

export class ApiCampaignCheckAccessIdGetArgs {
	id?: string;
	pass?: string;

	constructor(args: ApiCampaignCheckAccessIdGetArgs) {
        this.id = args.id;
        this.pass = args.pass;
	}
}

export class ApiCampaignIdRegistrationPostArgs {
	id?: string;

	constructor(args: ApiCampaignIdRegistrationPostArgs) {
        this.id = args.id;
	}
}

export class ApiCampaignIdRegistrationPutArgs {
	id?: string;

	constructor(args: ApiCampaignIdRegistrationPutArgs) {
        this.id = args.id;
	}
}

export class ApiCampaignIdRegistrationGetArgs {
	id?: string;
	page?: number;
	limit?: number;
	keyword?: string;

	constructor(args: ApiCampaignIdRegistrationGetArgs) {
        this.id = args.id;
        this.page = args.page;
        this.limit = args.limit;
        this.keyword = args.keyword;
	}
}

export class ApiCampaignRegistrationIdGetArgs {
	id?: string;

	constructor(args: ApiCampaignRegistrationIdGetArgs) {
        this.id = args.id;
	}
}

export class ApiCampaignIdRegistrationExportGetArgs {
	id?: string;
	time?: string;

	constructor(args: ApiCampaignIdRegistrationExportGetArgs) {
        this.id = args.id;
        this.time = args.time;
	}
}

export class ApiCampaignIdBulkWhitelistPostArgs {
	id?: string;

	constructor(args: ApiCampaignIdBulkWhitelistPostArgs) {
        this.id = args.id;
	}
}

export class ApiCampaignIdItemsGetArgs {
	id?: string;

	constructor(args: ApiCampaignIdItemsGetArgs) {
        this.id = args.id;
	}
}

export class ApiCampaignIdOrderPostArgs {
	id?: string;

	constructor(args: ApiCampaignIdOrderPostArgs) {
        this.id = args.id;
	}
}

export class ApiCampaignIdRemainQuotaGetArgs {
	id?: string;

	constructor(args: ApiCampaignIdRemainQuotaGetArgs) {
        this.id = args.id;
	}
}

export class ApiCampaignIdUserQuotaGetArgs {
	id?: string;

	constructor(args: ApiCampaignIdUserQuotaGetArgs) {
        this.id = args.id;
	}
}

export class ApiCampaignIdWhiteListGetArgs {
	id?: string;
	keyword?: string;

	constructor(args: ApiCampaignIdWhiteListGetArgs) {
        this.id = args.id;
        this.keyword = args.keyword;
	}
}

export class ApiCampaignIdWhiteListExportGetArgs {
	id?: string;
	time?: string;

	constructor(args: ApiCampaignIdWhiteListExportGetArgs) {
        this.id = args.id;
        this.time = args.time;
	}
}

export class ApiCampaignIdWhitelistPutArgs {
	id?: string;

	constructor(args: ApiCampaignIdWhitelistPutArgs) {
        this.id = args.id;
	}
}

export class ApiCampaignIdWhitelistPostArgs {
	id?: string;

	constructor(args: ApiCampaignIdWhitelistPostArgs) {
        this.id = args.id;
	}
}

export class ApiUserCampaignStatusGetArgs {
	id?: string;

	constructor(args: ApiUserCampaignStatusGetArgs) {
        this.id = args.id;
	}
}

export class ApiUserIdGetArgs {
	id?: string;

	constructor(args: ApiUserIdGetArgs) {
        this.id = args.id;
	}
}

export class ApiOrderHistoryCpIdGetArgs {
	cpId?: string;
	limit?: number;
	page?: number;
	keyword?: string;
	status?: number;

	constructor(args: ApiOrderHistoryCpIdGetArgs) {
        this.cpId = args.cpId;
        this.limit = args.limit;
        this.page = args.page;
        this.keyword = args.keyword;
        this.status = args.status;
	}
}

export class ApiOrderHistoryCpIdExportGetArgs {
	cpId?: string;
	keyword?: string;
	status?: number;
	time?: string;

	constructor(args: ApiOrderHistoryCpIdExportGetArgs) {
        this.cpId = args.cpId;
        this.keyword = args.keyword;
        this.status = args.status;
        this.time = args.time;
	}
}

export class ApiOrderOrderIdPutArgs {
	orderId?: string;

	constructor(args: ApiOrderOrderIdPutArgs) {
        this.orderId = args.orderId;
	}
}

export class ApiOrderOrderIdStatusPutArgs {
	orderId?: string;

	constructor(args: ApiOrderOrderIdStatusPutArgs) {
        this.orderId = args.orderId;
	}
}

export class ApiOrderHistoryCpIdUserGetArgs {
	cpId?: string;
	limit?: number;
	page?: number;
	keyword?: string;
	status?: number;

	constructor(args: ApiOrderHistoryCpIdUserGetArgs) {
        this.cpId = args.cpId;
        this.limit = args.limit;
        this.page = args.page;
        this.keyword = args.keyword;
        this.status = args.status;
	}
}

export class ApiOrderHistoryCpIdUserExportGetArgs {
	cpId?: string;
	keyword?: string;
	status?: number;
	time?: string;

	constructor(args: ApiOrderHistoryCpIdUserExportGetArgs) {
        this.cpId = args.cpId;
        this.keyword = args.keyword;
        this.status = args.status;
        this.time = args.time;
	}
}

export class ApiPaymentTokenNameGetArgs {
	name?: string;

	constructor(args: ApiPaymentTokenNameGetArgs) {
        this.name = args.name;
	}
}

export class ApiStakingPidVipStakingGetArgs {
	pid?: string;

	constructor(args: ApiStakingPidVipStakingGetArgs) {
        this.pid = args.pid;
	}
}

export class ApiStakingPidUserStatusGetArgs {
	pid?: string;

	constructor(args: ApiStakingPidUserStatusGetArgs) {
        this.pid = args.pid;
	}
}

export class ApiStakingPidStakePostArgs {
	pid?: string;

	constructor(args: ApiStakingPidStakePostArgs) {
        this.pid = args.pid;
	}
}

export class ApiStakingPidUnstakePostArgs {
	pid?: string;

	constructor(args: ApiStakingPidUnstakePostArgs) {
        this.pid = args.pid;
	}
}

export class ApiStakingStatisticGetArgs {
	real_time?: any;

	constructor(args: ApiStakingStatisticGetArgs) {
        this.real_time = args.real_time;
	}
}

export class ApiStakingExportGetArgs {
	time?: string;

	constructor(args: ApiStakingExportGetArgs) {
        this.time = args.time;
	}
}

export class ApiStakingWalletAddressStatusGetArgs {
	wallet_address?: string;

	constructor(args: ApiStakingWalletAddressStatusGetArgs) {
        this.wallet_address = args.wallet_address;
	}
}

export class ApiAdminStakingGetArgs {
	page?: number;
	limit?: number;
	keyword?: string;

	constructor(args: ApiAdminStakingGetArgs) {
        this.page = args.page;
        this.limit = args.limit;
        this.keyword = args.keyword;
	}
}

export class ApiAdminStakingIdPutArgs {
	id?: string;

	constructor(args: ApiAdminStakingIdPutArgs) {
        this.id = args.id;
	}
}

export class ApiAdminStakingIdDeleteArgs {
	id?: string;

	constructor(args: ApiAdminStakingIdDeleteArgs) {
        this.id = args.id;
	}
}

export class ApiRefundClaimCpidGetArgs {
	cpid?: string;

	constructor(args: ApiRefundClaimCpidGetArgs) {
        this.cpid = args.cpid;
	}
}

export class ApiRefundClaimCpidPostArgs {
	cpid?: string;

	constructor(args: ApiRefundClaimCpidPostArgs) {
        this.cpid = args.cpid;
	}
}

export class ApiRefundClaimCpidIdPutArgs {
	id?: string;
	cpid?: string;

	constructor(args: ApiRefundClaimCpidIdPutArgs) {
        this.id = args.id;
        this.cpid = args.cpid;
	}
}

export class ApiRefundClaimCpidIdDeleteArgs {
	id?: string;
	cpid?: string;

	constructor(args: ApiRefundClaimCpidIdDeleteArgs) {
        this.id = args.id;
        this.cpid = args.cpid;
	}
}

export class ApiAdminRefundClaimCpidGetArgs {
	cpid?: string;
	limit?: number;
	page?: number;
	keyword?: string;
	approveStatus?: string[];
	processStatus?: string[];
	refund?: any;

	constructor(args: ApiAdminRefundClaimCpidGetArgs) {
        this.cpid = args.cpid;
        this.limit = args.limit;
        this.page = args.page;
        this.keyword = args.keyword;
        this.approveStatus = args.approveStatus;
        this.processStatus = args.processStatus;
        this.refund = args.refund;
	}
}

export class ApiAdminRefundClaimIdApproveTxHashPutArgs {
	id?: string;
	txHash?: string;

	constructor(args: ApiAdminRefundClaimIdApproveTxHashPutArgs) {
        this.id = args.id;
        this.txHash = args.txHash;
	}
}

export class ApiAdminRefundClaimIdRejectPutArgs {
	id?: string;

	constructor(args: ApiAdminRefundClaimIdRejectPutArgs) {
        this.id = args.id;
	}
}

export class ApiAdminRefundClaimCpidExportGetArgs {
	cpid?: string;
	limit?: number;
	page?: number;
	keyword?: string;
	approveStatus?: string[];
	processStatus?: string[];
	refund?: any;
	time?: string;

	constructor(args: ApiAdminRefundClaimCpidExportGetArgs) {
        this.cpid = args.cpid;
        this.limit = args.limit;
        this.page = args.page;
        this.keyword = args.keyword;
        this.approveStatus = args.approveStatus;
        this.processStatus = args.processStatus;
        this.refund = args.refund;
        this.time = args.time;
	}
}

export class ApiItemCpIdGetArgs {
	cpId?: string;

	constructor(args: ApiItemCpIdGetArgs) {
        this.cpId = args.cpId;
	}
}

export class ApiItemCpIdPostArgs {
	cpId?: string;

	constructor(args: ApiItemCpIdPostArgs) {
        this.cpId = args.cpId;
	}
}

export class ApiItemItemIdPutArgs {
	itemId?: string;

	constructor(args: ApiItemItemIdPutArgs) {
        this.itemId = args.itemId;
	}
}

export class ApiItemItemIdDeleteArgs {
	itemId?: string;

	constructor(args: ApiItemItemIdDeleteArgs) {
        this.itemId = args.itemId;
	}
}

export class ApiItemCpIdSaleStatusGetArgs {
	cpId?: string;

	constructor(args: ApiItemCpIdSaleStatusGetArgs) {
        this.cpId = args.cpId;
	}
}

export class ApiUserAcceptItemTypeItemIdPostArgs {
	itemType?: string;
	itemId?: string;

	constructor(args: ApiUserAcceptItemTypeItemIdPostArgs) {
        this.itemType = args.itemType;
        this.itemId = args.itemId;
	}
}

export class ApiUserAcceptItemTypeItemIdGetArgs {
	itemType?: string;
	itemId?: string;

	constructor(args: ApiUserAcceptItemTypeItemIdGetArgs) {
        this.itemType = args.itemType;
        this.itemId = args.itemId;
	}
}

export class ApiAirdropGetArgs {
	page?: number;
	limit?: number;

	constructor(args: ApiAirdropGetArgs) {
        this.page = args.page;
        this.limit = args.limit;
	}
}

export class ApiAirdropIdPutArgs {
	id?: string;

	constructor(args: ApiAirdropIdPutArgs) {
        this.id = args.id;
	}
}

export class ApiAirdropIdDeleteArgs {
	id?: string;

	constructor(args: ApiAirdropIdDeleteArgs) {
        this.id = args.id;
	}
}

export class ApiVestingContractGetArgs {
	page?: number;
	limit?: number;

	constructor(args: ApiVestingContractGetArgs) {
        this.page = args.page;
        this.limit = args.limit;
	}
}

export class ApiVestingContractIdPutArgs {
	id?: string;

	constructor(args: ApiVestingContractIdPutArgs) {
        this.id = args.id;
	}
}

export class ApiVestingContractIdDeleteArgs {
	id?: string;

	constructor(args: ApiVestingContractIdDeleteArgs) {
        this.id = args.id;
	}
}

export class ApiVestingUserGetArgs {
	page?: number;
	limit?: number;
	keyword?: string;
	contract_id?: string;

	constructor(args: ApiVestingUserGetArgs) {
        this.page = args.page;
        this.limit = args.limit;
        this.keyword = args.keyword;
        this.contract_id = args.contract_id;
	}
}

export class ApiVestingUserTotalVestingGetArgs {
	contract_id?: string;

	constructor(args: ApiVestingUserTotalVestingGetArgs) {
        this.contract_id = args.contract_id;
	}
}

export class ApiVestingUserIdBulkPostArgs {
	id?: string;

	constructor(args: ApiVestingUserIdBulkPostArgs) {
        this.id = args.id;
	}
}

export class HealthApi {
  private readonly config: Configuration;

  constructor(config: Configuration | any) {
      this.config = new Configuration(config);
  }

  setConfig(config: Configuration | any) {
      this.config = new Configuration(config);
  }

	apiHealthGet(options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/health';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then(response => resolve(response as any));
            } else {
                promise.then(response => {
                    if (response.status === 200 || response.status === 204) {
                        return response.json();
                    } else {
                        reject(response);
                    }
                }).then(data => resolve(data));
            }
			promise.catch(error => reject(error));
		});
	}

	apiHealthRedisGet(options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/health/redis';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiHealthIpGet(options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/health/ip';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}
}

export const getHealthApi = (accessToken?: string) => getApiService('HealthApi', HealthApi, accessToken);

export class AuthApi {
  private readonly config: Configuration;

  constructor(config: Configuration | any) {
      this.config = new Configuration(config);
  }

  setConfig(config: Configuration | any) {
      this.config = new Configuration(config);
  }

	apiAuthAdminNonceAddrGet(args: ApiAuthAdminNonceAddrGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { addr } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/auth/admin/nonce/{addr}';
		url = url.split(['{', '}'].join('addr')).join(encodeURIComponent(String(addr)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAuthAdminLoginPost(body?: Web3LoginDTO, options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/auth/admin/login';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAuthAdminChangePassPost(body?: AdminLogInDto, options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/auth/admin/change-pass';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAuthAdminTokenInfoGet(options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/auth/admin/token-info';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAuthUserNonceAddrGet(args: ApiAuthUserNonceAddrGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { addr } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/auth/user/nonce/{addr}';
		url = url.split(['{', '}'].join('addr')).join(encodeURIComponent(String(addr)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAuthUserLoginPost(body?: Web3LoginDTO, options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/auth/user/login';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAuthUserTokenInfoGet(options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/auth/user/token-info';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}
}

export const getAuthApi = (accessToken?: string) => getApiService('AuthApi', AuthApi, accessToken);

export class CampaignApi {
  private readonly config: Configuration;

  constructor(config: Configuration | any) {
      this.config = new Configuration(config);
  }

  setConfig(config: Configuration | any) {
      this.config = new Configuration(config);
  }

	apiCampaignPost(body?: CampaignDtoSection1, options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/campaign';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiCampaignGet(args: ApiCampaignGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { limit, page, keyword, status, types, includeRemainQuota, includePurchasedAmount, on_banner, includePrivateCampaign, orderBy } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/campaign';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (limit !== undefined) {
            params.append('limit', limit as any);
        }
        if (page !== undefined) {
            params.append('page', page as any);
        }
        if (keyword !== undefined) {
            params.append('keyword', keyword as any);
        }
        if (status !== undefined) {
            (status || []).forEach(item => {
                params.append('status', item as any);
            });
        }
        if (types !== undefined) {
            (types || []).forEach(item => {
                params.append('types', item as any);
            });
        }
        if (includeRemainQuota !== undefined) {
            params.append('includeRemainQuota', includeRemainQuota as any);
        }
        if (includePurchasedAmount !== undefined) {
            params.append('includePurchasedAmount', includePurchasedAmount as any);
        }
        if (on_banner !== undefined) {
            params.append('on_banner', on_banner as any);
        }
        if (includePrivateCampaign !== undefined) {
            params.append('includePrivateCampaign', includePrivateCampaign as any);
        }
        if (orderBy !== undefined) {
            params.append('orderBy', orderBy as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiCampaignIdPut(args: ApiCampaignIdPutArgs, body?: UpdateCampaignDto, options: MethodOptions | any = {}): Promise<any> { 
        const { id } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/campaign/{id}';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'put',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiCampaignIdGet(args: ApiCampaignIdGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { id, section } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/campaign/{id}';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (section !== undefined) {
            params.append('section', section as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiCampaignIdDelete(args: ApiCampaignIdDeleteArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { id } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/campaign/{id}';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'delete',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiCampaignIdStatusPut(args: ApiCampaignIdStatusPutArgs, body?: UpdateCampaignStatusDto, options: MethodOptions | any = {}): Promise<any> { 
        const { id } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/campaign/{id}/status';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'put',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiCampaignIdToRawIdGet(args: ApiCampaignIdToRawIdGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { id } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/campaign/id-to-raw-id';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (id !== undefined) {
            params.append('id', id as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiCampaignHasOrderHistoryGet(options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/campaign/has-order-history';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiCampaignCheckAccessIdGet(args: ApiCampaignCheckAccessIdGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { id, pass } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/campaign/check-access/{id}';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (pass !== undefined) {
            params.append('pass', pass as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiCampaignIdRegistrationPost(args: ApiCampaignIdRegistrationPostArgs, body?: UserCampaignRegistration, options: MethodOptions | any = {}): Promise<any> { 
        const { id } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/campaign/{id}/registration';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiCampaignIdRegistrationPut(args: ApiCampaignIdRegistrationPutArgs, body?: UserCampaignRegistration, options: MethodOptions | any = {}): Promise<any> { 
        const { id } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/campaign/{id}/registration';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'put',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiCampaignIdRegistrationGet(args: ApiCampaignIdRegistrationGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { id, page, limit, keyword } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/campaign/{id}/registration';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (page !== undefined) {
            params.append('page', page as any);
        }
        if (limit !== undefined) {
            params.append('limit', limit as any);
        }
        if (keyword !== undefined) {
            params.append('keyword', keyword as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiCampaignRegistrationIdGet(args: ApiCampaignRegistrationIdGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { id } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/campaign/registration/{id}';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiCampaignIdRegistrationExportGet(args: ApiCampaignIdRegistrationExportGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { id, time } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/campaign/{id}/registration/export';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (time !== undefined) {
            params.append('time', time as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiCampaignIdBulkWhitelistPost(args: ApiCampaignIdBulkWhitelistPostArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { id } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/campaign/{id}/bulk-whitelist';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiCampaignIdItemsGet(args: ApiCampaignIdItemsGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { id } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/campaign/{id}/items';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiCampaignIdOrderPost(args: ApiCampaignIdOrderPostArgs, body?: CreateOrderDto, options: MethodOptions | any = {}): Promise<any> { 
        const { id } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/campaign/{id}/order';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiCampaignIdRemainQuotaGet(args: ApiCampaignIdRemainQuotaGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { id } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/campaign/{id}/remain-quota';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiCampaignIdUserQuotaGet(args: ApiCampaignIdUserQuotaGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { id } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/campaign/{id}/user-quota';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiCampaignIdWhiteListGet(args: ApiCampaignIdWhiteListGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { id, keyword } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/campaign/{id}/white-list';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (keyword !== undefined) {
            params.append('keyword', keyword as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiCampaignIdWhiteListExportGet(args: ApiCampaignIdWhiteListExportGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { id, time } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/campaign/{id}/white-list/export';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (time !== undefined) {
            params.append('time', time as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiCampaignIdWhitelistPut(args: ApiCampaignIdWhitelistPutArgs, body?: UpdateWhiteListRecordDto, options: MethodOptions | any = {}): Promise<any> { 
        const { id } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/campaign/{id}/whitelist';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'put',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiCampaignIdWhitelistPost(args: ApiCampaignIdWhitelistPostArgs, body?: UpdateWhiteListRecordDto, options: MethodOptions | any = {}): Promise<any> { 
        const { id } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/campaign/{id}/whitelist';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}
}

export const getCampaignApi = (accessToken?: string) => getApiService('CampaignApi', CampaignApi, accessToken);

export class UploadApi {
  private readonly config: Configuration;

  constructor(config: Configuration | any) {
      this.config = new Configuration(config);
  }

  setConfig(config: Configuration | any) {
      this.config = new Configuration(config);
  }

	apiUploadImageGcpPost(body?: CreateUploadLinkDto, options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/upload/image/gcp';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiUploadImagePost(body?: CreateUploadLinkDto, options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/upload/image';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}
}

export const getUploadApi = (accessToken?: string) => getApiService('UploadApi', UploadApi, accessToken);

export class SystemConfigApi {
  private readonly config: Configuration;

  constructor(config: Configuration | any) {
      this.config = new Configuration(config);
  }

  setConfig(config: Configuration | any) {
      this.config = new Configuration(config);
  }

	apiSystemConfigGet(options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/system-config';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiSystemConfigKycGet(options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/system-config/kyc';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiSystemConfigRpcGet(options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/system-config/rpc';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiSystemConfigRpcConfigurationPost(body?: RpcConfigurationDto, options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/system-config/rpc/configuration';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}
}

export const getSystemConfigApi = (accessToken?: string) => getApiService('SystemConfigApi', SystemConfigApi, accessToken);

export class UserApi {
  private readonly config: Configuration;

  constructor(config: Configuration | any) {
      this.config = new Configuration(config);
  }

  setConfig(config: Configuration | any) {
      this.config = new Configuration(config);
  }

	apiUserGet(options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/user';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiUserPut(body?: UpdateUserProfileDto, options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/user';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'put',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiUserCampaignStatusGet(args: ApiUserCampaignStatusGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { id } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/user/campaign-status';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (id !== undefined) {
            params.append('id', id as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiUserIdGet(args: ApiUserIdGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { id } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/user/{id}';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}
}

export const getUserApi = (accessToken?: string) => getApiService('UserApi', UserApi, accessToken);

export class OrderApi {
  private readonly config: Configuration;

  constructor(config: Configuration | any) {
      this.config = new Configuration(config);
  }

  setConfig(config: Configuration | any) {
      this.config = new Configuration(config);
  }

	apiOrderHistoryCpIdGet(args: ApiOrderHistoryCpIdGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { cpId, limit, page, keyword, status } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/order/history/{cpId}';
		url = url.split(['{', '}'].join('cpId')).join(encodeURIComponent(String(cpId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (limit !== undefined) {
            params.append('limit', limit as any);
        }
        if (page !== undefined) {
            params.append('page', page as any);
        }
        if (keyword !== undefined) {
            params.append('keyword', keyword as any);
        }
        if (status !== undefined) {
            params.append('status', status as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiOrderHistoryCpIdExportGet(args: ApiOrderHistoryCpIdExportGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { cpId, keyword, status, time } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/order/history/{cpId}/export';
		url = url.split(['{', '}'].join('cpId')).join(encodeURIComponent(String(cpId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (keyword !== undefined) {
            params.append('keyword', keyword as any);
        }
        if (status !== undefined) {
            params.append('status', status as any);
        }
        if (time !== undefined) {
            params.append('time', time as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiOrderOrderIdPut(args: ApiOrderOrderIdPutArgs, body?: UpdateOderDto, options: MethodOptions | any = {}): Promise<any> { 
        const { orderId } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/order/{orderId}';
		url = url.split(['{', '}'].join('orderId')).join(encodeURIComponent(String(orderId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'put',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiOrderOrderIdStatusPut(args: ApiOrderOrderIdStatusPutArgs, body?: UpdateOderStatusDto, options: MethodOptions | any = {}): Promise<any> { 
        const { orderId } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/order/{orderId}/status';
		url = url.split(['{', '}'].join('orderId')).join(encodeURIComponent(String(orderId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'put',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiOrderHistoryCpIdUserGet(args: ApiOrderHistoryCpIdUserGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { cpId, limit, page, keyword, status } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/order/history/{cpId}/user';
		url = url.split(['{', '}'].join('cpId')).join(encodeURIComponent(String(cpId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (limit !== undefined) {
            params.append('limit', limit as any);
        }
        if (page !== undefined) {
            params.append('page', page as any);
        }
        if (keyword !== undefined) {
            params.append('keyword', keyword as any);
        }
        if (status !== undefined) {
            params.append('status', status as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiOrderHistoryCpIdUserExportGet(args: ApiOrderHistoryCpIdUserExportGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { cpId, keyword, status, time } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/order/history/{cpId}/user/export';
		url = url.split(['{', '}'].join('cpId')).join(encodeURIComponent(String(cpId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (keyword !== undefined) {
            params.append('keyword', keyword as any);
        }
        if (status !== undefined) {
            params.append('status', status as any);
        }
        if (time !== undefined) {
            params.append('time', time as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}
}

export const getOrderApi = (accessToken?: string) => getApiService('OrderApi', OrderApi, accessToken);

export class PaymentTokenApi {
  private readonly config: Configuration;

  constructor(config: Configuration | any) {
      this.config = new Configuration(config);
  }

  setConfig(config: Configuration | any) {
      this.config = new Configuration(config);
  }

	apiPaymentTokenGet(options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/payment-token';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiPaymentTokenNameGet(args: ApiPaymentTokenNameGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { name } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/payment-token/{name}';
		url = url.split(['{', '}'].join('name')).join(encodeURIComponent(String(name)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}
}

export const getPaymentTokenApi = (accessToken?: string) => getApiService('PaymentTokenApi', PaymentTokenApi, accessToken);

export class StakingApi {
  private readonly config: Configuration;

  constructor(config: Configuration | any) {
      this.config = new Configuration(config);
  }

  setConfig(config: Configuration | any) {
      this.config = new Configuration(config);
  }

	apiStakingCreatePoolGet(options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/staking/createPool';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiStakingPidVipStakingGet(args: ApiStakingPidVipStakingGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { pid } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/staking/{pid}/vip-staking';
		url = url.split(['{', '}'].join('pid')).join(encodeURIComponent(String(pid)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiStakingPidUserStatusGet(args: ApiStakingPidUserStatusGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { pid } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/staking/{pid}/user-status';
		url = url.split(['{', '}'].join('pid')).join(encodeURIComponent(String(pid)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiStakingPidStakePost(args: ApiStakingPidStakePostArgs, body?: StakeUnStakeDto, options: MethodOptions | any = {}): Promise<any> { 
        const { pid } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/staking/{pid}/stake';
		url = url.split(['{', '}'].join('pid')).join(encodeURIComponent(String(pid)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiStakingPidUnstakePost(args: ApiStakingPidUnstakePostArgs, body?: StakeUnStakeDto, options: MethodOptions | any = {}): Promise<any> { 
        const { pid } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/staking/{pid}/unstake';
		url = url.split(['{', '}'].join('pid')).join(encodeURIComponent(String(pid)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiStakingStatisticGet(args: ApiStakingStatisticGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { real_time } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/staking/statistic';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (real_time !== undefined) {
            params.append('real_time', real_time as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiStakingExportGet(args: ApiStakingExportGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { time } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/staking/export';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (time !== undefined) {
            params.append('time', time as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiStakingPoolsGet(options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/staking/pools';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiStakingUserStatusGet(options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/staking/user-status';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiStakingWalletAddressStatusGet(args: ApiStakingWalletAddressStatusGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { wallet_address } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/staking/{wallet_address}/status';
		url = url.split(['{', '}'].join('wallet_address')).join(encodeURIComponent(String(wallet_address)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiStakingBulkStakingDataPost(body?: any, options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/staking/bulk-staking-data';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
            const formData = new FormData();
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body,
                body: formData
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}
}

export const getStakingApi = (accessToken?: string) => getApiService('StakingApi', StakingApi, accessToken);

export class AdminStakingApi {
  private readonly config: Configuration;

  constructor(config: Configuration | any) {
      this.config = new Configuration(config);
  }

  setConfig(config: Configuration | any) {
      this.config = new Configuration(config);
  }

	apiAdminStakingGet(args: ApiAdminStakingGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { page, limit, keyword } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/admin/staking';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (page !== undefined) {
            params.append('page', page as any);
        }
        if (limit !== undefined) {
            params.append('limit', limit as any);
        }
        if (keyword !== undefined) {
            params.append('keyword', keyword as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAdminStakingPost(body?: CreateUpdateStakingPoolDto, options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/admin/staking';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAdminStakingIdPut(args: ApiAdminStakingIdPutArgs, body?: CreateUpdateStakingPoolDto, options: MethodOptions | any = {}): Promise<any> { 
        const { id } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/admin/staking/{id}';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'put',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAdminStakingIdDelete(args: ApiAdminStakingIdDeleteArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { id } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/admin/staking/{id}';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'delete',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}
}

export const getAdminStakingApi = (accessToken?: string) => getApiService('AdminStakingApi', AdminStakingApi, accessToken);

export class RefundClaimApi {
  private readonly config: Configuration;

  constructor(config: Configuration | any) {
      this.config = new Configuration(config);
  }

  setConfig(config: Configuration | any) {
      this.config = new Configuration(config);
  }

	apiRefundClaimCpidGet(args: ApiRefundClaimCpidGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { cpid } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/refund-claim/{cpid}';
		url = url.split(['{', '}'].join('cpid')).join(encodeURIComponent(String(cpid)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiRefundClaimCpidPost(args: ApiRefundClaimCpidPostArgs, body?: RefundClaimRequestDto, options: MethodOptions | any = {}): Promise<any> { 
        const { cpid } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/refund-claim/{cpid}';
		url = url.split(['{', '}'].join('cpid')).join(encodeURIComponent(String(cpid)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiRefundClaimCpidIdPut(args: ApiRefundClaimCpidIdPutArgs, body?: ConfirmRefundClaimRequestDto, options: MethodOptions | any = {}): Promise<any> { 
        const { id, cpid } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/refund-claim/{cpid}/{id}';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
		url = url.split(['{', '}'].join('cpid')).join(encodeURIComponent(String(cpid)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'put',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiRefundClaimCpidIdDelete(args: ApiRefundClaimCpidIdDeleteArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { id, cpid } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/refund-claim/{cpid}/{id}';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
		url = url.split(['{', '}'].join('cpid')).join(encodeURIComponent(String(cpid)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'delete',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAdminRefundClaimCpidGet(args: ApiAdminRefundClaimCpidGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { cpid, limit, page, keyword, approveStatus, processStatus, refund } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/admin/refund-claim/{cpid}';
		url = url.split(['{', '}'].join('cpid')).join(encodeURIComponent(String(cpid)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (limit !== undefined) {
            params.append('limit', limit as any);
        }
        if (page !== undefined) {
            params.append('page', page as any);
        }
        if (keyword !== undefined) {
            params.append('keyword', keyword as any);
        }
        if (approveStatus !== undefined) {
            (approveStatus || []).forEach(item => {
                params.append('approveStatus', item as any);
            });
        }
        if (processStatus !== undefined) {
            (processStatus || []).forEach(item => {
                params.append('processStatus', item as any);
            });
        }
        if (refund !== undefined) {
            params.append('refund', refund as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAdminRefundClaimIdApproveTxHashPut(args: ApiAdminRefundClaimIdApproveTxHashPutArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { id, txHash } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/admin/refund-claim/{id}/approve/{txHash}';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
		url = url.split(['{', '}'].join('txHash')).join(encodeURIComponent(String(txHash)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'put',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAdminRefundClaimIdRejectPut(args: ApiAdminRefundClaimIdRejectPutArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { id } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/admin/refund-claim/{id}/reject';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'put',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAdminRefundClaimCpidExportGet(args: ApiAdminRefundClaimCpidExportGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { cpid, limit, page, keyword, approveStatus, processStatus, refund, time } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/admin/refund-claim/{cpid}/export';
		url = url.split(['{', '}'].join('cpid')).join(encodeURIComponent(String(cpid)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (limit !== undefined) {
            params.append('limit', limit as any);
        }
        if (page !== undefined) {
            params.append('page', page as any);
        }
        if (keyword !== undefined) {
            params.append('keyword', keyword as any);
        }
        if (approveStatus !== undefined) {
            (approveStatus || []).forEach(item => {
                params.append('approveStatus', item as any);
            });
        }
        if (processStatus !== undefined) {
            (processStatus || []).forEach(item => {
                params.append('processStatus', item as any);
            });
        }
        if (refund !== undefined) {
            params.append('refund', refund as any);
        }
        if (time !== undefined) {
            params.append('time', time as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}
}

export const getRefundClaimApi = (accessToken?: string) => getApiService('RefundClaimApi', RefundClaimApi, accessToken);

export class ItemApi {
  private readonly config: Configuration;

  constructor(config: Configuration | any) {
      this.config = new Configuration(config);
  }

  setConfig(config: Configuration | any) {
      this.config = new Configuration(config);
  }

	apiItemCpIdGet(args: ApiItemCpIdGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { cpId } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/item/{cpId}';
		url = url.split(['{', '}'].join('cpId')).join(encodeURIComponent(String(cpId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiItemCpIdPost(args: ApiItemCpIdPostArgs, body?: CreateUpdateItemsDTO, options: MethodOptions | any = {}): Promise<any> { 
        const { cpId } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/item/{cpId}';
		url = url.split(['{', '}'].join('cpId')).join(encodeURIComponent(String(cpId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiItemItemIdPut(args: ApiItemItemIdPutArgs, body?: CreateUpdateItemsDTO, options: MethodOptions | any = {}): Promise<any> { 
        const { itemId } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/item/{itemId}';
		url = url.split(['{', '}'].join('itemId')).join(encodeURIComponent(String(itemId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'put',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiItemItemIdDelete(args: ApiItemItemIdDeleteArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { itemId } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/item/{itemId}';
		url = url.split(['{', '}'].join('itemId')).join(encodeURIComponent(String(itemId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'delete',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiItemCpIdSaleStatusGet(args: ApiItemCpIdSaleStatusGetArgs, options: MethodOptions | any = {}): Promise<StatusItemResponse[]> { 
        const { cpId } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/item/{cpId}/sale-status';
		url = url.split(['{', '}'].join('cpId')).join(encodeURIComponent(String(cpId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then(response => resolve(response as any));
            } else {
                promise.then(response => {
                    if (response.status === 200 || response.status === 204) {
                        return response.json();
                    } else {
                        reject(response);
                    }
                }).then(data => resolve(data));
            }
			promise.catch(error => reject(error));
		});
	}
}

export const getItemApi = (accessToken?: string) => getApiService('ItemApi', ItemApi, accessToken);

export class UserAcceptApi {
  private readonly config: Configuration;

  constructor(config: Configuration | any) {
      this.config = new Configuration(config);
  }

  setConfig(config: Configuration | any) {
      this.config = new Configuration(config);
  }

	apiUserAcceptItemTypeItemIdPost(args: ApiUserAcceptItemTypeItemIdPostArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { itemType, itemId } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/user-accept/{itemType}/{itemId}';
		url = url.split(['{', '}'].join('itemType')).join(encodeURIComponent(String(itemType)));
		url = url.split(['{', '}'].join('itemId')).join(encodeURIComponent(String(itemId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiUserAcceptItemTypeItemIdGet(args: ApiUserAcceptItemTypeItemIdGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { itemType, itemId } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/user-accept/{itemType}/{itemId}';
		url = url.split(['{', '}'].join('itemType')).join(encodeURIComponent(String(itemType)));
		url = url.split(['{', '}'].join('itemId')).join(encodeURIComponent(String(itemId)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}
}

export const getUserAcceptApi = (accessToken?: string) => getApiService('UserAcceptApi', UserAcceptApi, accessToken);

export class AirdropApi {
  private readonly config: Configuration;

  constructor(config: Configuration | any) {
      this.config = new Configuration(config);
  }

  setConfig(config: Configuration | any) {
      this.config = new Configuration(config);
  }

	apiAirdropGet(args: ApiAirdropGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { page, limit } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/airdrop';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (page !== undefined) {
            params.append('page', page as any);
        }
        if (limit !== undefined) {
            params.append('limit', limit as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAirdropPost(body?: CreateUpdateAirdropDto, options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/airdrop';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAirdropLookupGet(options: MethodOptions | any = {}): Promise<any> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/airdrop/lookup';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAirdropIdPut(args: ApiAirdropIdPutArgs, body?: CreateUpdateAirdropDto, options: MethodOptions | any = {}): Promise<any> { 
        const { id } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/airdrop/{id}';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'put',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiAirdropIdDelete(args: ApiAirdropIdDeleteArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { id } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/airdrop/{id}';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'delete',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}
}

export const getAirdropApi = (accessToken?: string) => getApiService('AirdropApi', AirdropApi, accessToken);

export class VestingContractApi {
  private readonly config: Configuration;

  constructor(config: Configuration | any) {
      this.config = new Configuration(config);
  }

  setConfig(config: Configuration | any) {
      this.config = new Configuration(config);
  }

	apiVestingContractGet(args: ApiVestingContractGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { page, limit } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/vesting-contract';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (page !== undefined) {
            params.append('page', page as any);
        }
        if (limit !== undefined) {
            params.append('limit', limit as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiVestingContractPost(body?: CreateUpdateVestingContractDto, options: MethodOptions | any = {}): Promise<VestingContractResponse> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/vesting-contract';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then(response => resolve(response as any));
            } else {
                promise.then(response => {
                    if (response.status === 200 || response.status === 204) {
                        return response.json();
                    } else {
                        reject(response);
                    }
                }).then(data => resolve(data));
            }
			promise.catch(error => reject(error));
		});
	}

	apiVestingContractLookupGet(options: MethodOptions | any = {}): Promise<VestingContractResponse[]> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/vesting-contract/lookup';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then(response => resolve(response as any));
            } else {
                promise.then(response => {
                    if (response.status === 200 || response.status === 204) {
                        return response.json();
                    } else {
                        reject(response);
                    }
                }).then(data => resolve(data));
            }
			promise.catch(error => reject(error));
		});
	}

	apiVestingContractIdPut(args: ApiVestingContractIdPutArgs, body?: CreateUpdateVestingContractDto, options: MethodOptions | any = {}): Promise<VestingContractResponse> { 
        const { id } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/vesting-contract/{id}';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'put',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then(response => resolve(response as any));
            } else {
                promise.then(response => {
                    if (response.status === 200 || response.status === 204) {
                        return response.json();
                    } else {
                        reject(response);
                    }
                }).then(data => resolve(data));
            }
			promise.catch(error => reject(error));
		});
	}

	apiVestingContractIdDelete(args: ApiVestingContractIdDeleteArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { id } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/vesting-contract/{id}';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'delete',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}
}

export const getVestingContractApi = (accessToken?: string) => getApiService('VestingContractApi', VestingContractApi, accessToken);

export class VestingUserApi {
  private readonly config: Configuration;

  constructor(config: Configuration | any) {
      this.config = new Configuration(config);
  }

  setConfig(config: Configuration | any) {
      this.config = new Configuration(config);
  }

	apiVestingUserPost(body?: CreateVestingUserDto, options: MethodOptions | any = {}): Promise<VestingUserResponse> { 
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/vesting-user';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
                body: 'object' === typeof body ? JSON.stringify(body) : body
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then(response => resolve(response as any));
            } else {
                promise.then(response => {
                    if (response.status === 200 || response.status === 204) {
                        return response.json();
                    } else {
                        reject(response);
                    }
                }).then(data => resolve(data));
            }
			promise.catch(error => reject(error));
		});
	}

	apiVestingUserGet(args: ApiVestingUserGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { page, limit, keyword, contract_id } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/vesting-user';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (page !== undefined) {
            params.append('page', page as any);
        }
        if (limit !== undefined) {
            params.append('limit', limit as any);
        }
        if (keyword !== undefined) {
            params.append('keyword', keyword as any);
        }
        if (contract_id !== undefined) {
            params.append('contract_id', contract_id as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiVestingUserTotalVestingGet(args: ApiVestingUserTotalVestingGetArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { contract_id } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/vesting-user/total-vesting';
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (contract_id !== undefined) {
            params.append('contract_id', contract_id as any);
        }
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'get',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}

	apiVestingUserIdBulkPost(args: ApiVestingUserIdBulkPostArgs, options: MethodOptions | any = {}): Promise<any> { 
        const { id } = args;
        const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
		let url = '/api/vesting-user/{id}/bulk';
		url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
		const query = params.toString();
		return new Promise((resolve, reject) => {
			const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
				method: 'post',
                headers: { ...headers, ...getHeaders(), ...options.headers}
			});
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then(response => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
			promise.catch(error => reject(error));
		});
	}
}

export const getVestingUserApi = (accessToken?: string) => getApiService('VestingUserApi', VestingUserApi, accessToken);
