import { FAQs, MyVipLevel, ToNextLevel, TotalStaked, VipTiers, VipTiersMobile, OpenPoolsTable, ClosePoolsTable } from './components';
import './style.scss';

export const Home = () => {
  return (
    <>
      {/* <TopPanel /> */}
      <div className='page-container container mx-auto py-6 gap-[60px] flex flex-col px-4'>
        <div className='lg:grid hidden' id='StakeUnstacke'>
          <VipTiers />
        </div>
        <div className='flex lg:hidden'>
          <VipTiersMobile />
        </div>
        <div className=''>
          <div className='text-white font-bold text-2xl mb-4'>STAKING DEFI</div>
          <div className='grid grid-cols-1 md:grid-cols-3 gap-6 mb-6'>
            <TotalStaked />
            <MyVipLevel />
            <ToNextLevel />
          </div>
          <div className='text-white font-bold text-2xl mb-4'>ACTIVE POOLS</div>
          <OpenPoolsTable />
          <div className='text-white font-bold text-2xl mb-4 mt-8'>CLOSED POOLS</div>
          <ClosePoolsTable />
        </div>
        <div className=''>
          <FAQs />
        </div>
      </div>
    </>
  );
};
